import React, { Component } from 'react';

import { getCalendarData } from '../../../utilities/utilities';

import CampaignDays from '../../modules/campaign-days';
import CalendarCampaign from '../calendar-campaign';

export default class CampaignBoxDateResume extends Component{
  render(){
    const { currentCampaign, campaignStates } = this.props,
          calendarData = getCalendarData(currentCampaign.get('fecha_inicio'), currentCampaign.get('fecha_termino'));

    return (
      <div className="campaign-box-wrapper">
        <h4 className="m-b-0">Envío</h4>
        <span className="secondary-data">{ currentCampaign.get('hr_envio') +':00 hrs.' }</span>
        <div className="wrapper wrapper-vertical">
          <CampaignDays
            days={ currentCampaign.get('dias_envio') }
            additionalHolderClass={ 'align-center' }
          />
        </div>
        <div className="scrolled-box inner m-b-20">
          <CalendarCampaign 
            months={ calendarData }
            startDate={ currentCampaign.get('fecha_inicio') }
            endDate={ currentCampaign.get('fecha_termino') }
            days={ currentCampaign.get('dias_envio').toJS() }
            concurrency={ currentCampaign.get('concurrencia') }
            exceptionDate={ currentCampaign.get('dias_excepcion') }
          />
        </div>
      </div>
    );
  }
}