import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class BarPercent extends Component{
  getPercentBarCompleted(){
    const { total, completed } = this.props;

    return (Math.round((completed * 100) / total)) + '%';
  }
  getValues(){
    const { showValues, total, completed } = this.props;

    if(showValues){
      return(
        <div className="percent-bar-values-holder">
          <span className="percent-bar-value left">{ this.getPercentBarCompleted() }</span>
          <span className="percent-bar-value right">{ completed + '/'+ total }</span>
        </div>
      );
    }
    return null;
  }
  render(){
    const percentBarCompleted = this.getPercentBarCompleted(),
          values = this.getValues();

    return (
      <div className="percent-bar-holder">
        <div className="percent-bar">
          <span className="percent-bar-completed" style={{ width: percentBarCompleted }}></span>
        </div>
        { values }
      </div>
    );
  }
}

BarPercent.propTypes = {
  total: PropTypes.number,
  completed: PropTypes.number,
  showValues: PropTypes.bool
}

BarPercent.defaultProps = {
  showValues: true
}
