import React, { Component } from 'react';
import { getIdEnterprise } from '../../utilities/utilities';

export default class AssistantFilters extends Component{
  constructor(props){
    super(props);

    this.cleanConversation = this.cleanConversation.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
  }
  cleanConversation(event){
    event.preventDefault();
    const { assistantSettingsStates, currentUser } = this.props;

    this.props.cleanAssistant({
      contextID: 'TESTS',
      tipo_cliente: assistantSettingsStates.get('type'),
      tipo: assistantSettingsStates.get('channel'),
      empresa: getIdEnterprise(this.props)
    })
  }
  inputHandler(target, event){
    this.props.setAssistantSettingsState(target, event.target.value);
  }
  getChannelSelector(){
    const { assistantSettingsStates, featuresStates } = this.props,
          features = featuresStates.get('features');

    if(!features.get('campaignsFeatures').get('enableWhatsapp')){ return null; }

    return (
      <label>
        <span className="label-like">Tipo de canal:</span>
        <select value={ assistantSettingsStates.get('channel') } onChange={ this.inputHandler.bind(null, 'channel') } >
          <option value="web">Web</option>
          <option value="whatsapp">Whatsapp</option>
          <option value="voz">Voz</option>
        </select>
      </label>
    );
  }
  render(){
    const { assistantSettingsStates } = this.props,
          channelSelector = this.getChannelSelector();

    return(
      <div className="assistant-filter">
        <h3>Demo:</h3>
        <label>
          <span className="label-like">Tipo de cliente:</span>
          <select value={ assistantSettingsStates.get('type') } onChange={ this.inputHandler.bind(null, 'type') } >
            <option value="persona">Persona</option>
            <option value="empresa">Empresa</option>
          </select>
        </label>
        { channelSelector }
        <div className="align-center">
          <button className="btn btn-full btn-small btn-secondary" onClick={ this.cleanConversation }>Filtrar</button>  
        </div>
      </div>
    );
  }
}