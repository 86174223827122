import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BarPercent from '../modules/bar-percent';

export default class CampaignBoxUserResume extends Component{
  getTitle(){
    const { campaign, showTitle } = this.props;
     let chanelIcon = '';
    if(campaign.get('canales') == "whatsapp"){
      chanelIcon = 'whatsapp';
    }
    if(campaign.get('canales') == "sms"){
      chanelIcon = 'sms';
    }
    if(campaign.get('canales') == "email"){
      chanelIcon = 'email';
    }
    if(campaign.get('canales') == "voz"){
      chanelIcon = 'voz';
    }

    if(!showTitle){ return null; }

    return (
      <span>
        <i className={`icon-element clients inline-left`}></i>
        {campaign.get('clientes')} Clientes
        <i className={`${chanelIcon}`}></i>
        
      </span>
      
    );
  }
  render(){
    const { campaign, wrapper } = this.props,
          title = this.getTitle();

    return(
      <div className={`${ wrapper ? 'campaign-box-wrapper' : '' }`}>
        <div className="campaign-client-visualization-holder active">
          <span className="campaign-client-visualization-header">
            { title }
            <BarPercent total={campaign.get('clientes')} completed={ campaign.get('clientes_contactados')} />
          </span>
        </div>
      </div>
    );
  }
}

CampaignBoxUserResume.propTypes = {
  campaign: PropTypes.object,
  wrapper: PropTypes.bool,
  showTitle: PropTypes.bool
}

CampaignBoxUserResume.defaultProps = {
  wrapper: true,
  showTitle: true
}
