import React, { Component } from 'react';
import NotFoundData from '../modules/not-found-data';
import Tooltip from '../modules/tooltip';
import FormValidizr from '../form-validizr/form-validizr';
import Disclaimer from '../modules/disclaimer';
import { getIndex, getIdEnterprise } from '../../utilities/utilities';

export default class AdminEnterpriseVariables extends Component {
    constructor(props) {
        super(props);
        this.inputHandler = this.inputHandler.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillMount = () => {

    }
    handleSubmit() {

        const { adminEnterprisesStates } = this.props,
            data = adminEnterprisesStates.get('enterpriseVariablesForm').toJS();
        data.empresa = getIdEnterprise(this.props);
        this.props.editVariablesEnterprise(data);
    }
    inputHandler(target, event) {
        const { adminEnterprisesStates } = this.props,
            enterpriseForm = adminEnterprisesStates.get('enterpriseVariablesForm');
        let value = event.target.value;

        if (event.target.type == 'checkbox')
            value = !enterpriseForm.get(target);

        this.props.changeFormFieldsVariablesEnterprise(target, value)
    }
    getFormFieldsets() {
        const { adminEnterprisesStates } = this.props,
            enterpriseForm = adminEnterprisesStates.get('enterpriseVariablesForm');

        let formFields = [
            {
                title: 'Variables empresa',
                fieldsetClass: 'grid-4',
                data: [
                    {
                        tag: 'input',
                        type: 'checkbox',
                        name: 'alcanza-limite',
                        labelClass: 'grid-12',
                        checked: enterpriseForm.get('alcanza_limite'),
                        value: enterpriseForm.get('alcanza_limite'),
                        label: 'Alcanza límite',
                        positionLabel: 'top',
                        onChangeFunc: this.inputHandler.bind(null, 'alcanza_limite')
                    },
                    {
                        tag: 'input',
                        type: 'number',
                        name: 'limite_campana',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar el límite de campañas para la empresa" />
                        },
                        placeholder: 'Ingrese el límite de campañas de la empresa',
                        value: enterpriseForm.get('limite_campana'),
                        label: 'Límite de campañas:',
                        onChangeFunc: this.inputHandler.bind(null, 'limite_campana'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'number',
                        name: 'limite_clientes',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un límite de clientes para la empresa" />
                        },
                        placeholder: 'Ingrese el límite de clientes de la empresa',
                        value: enterpriseForm.get('limite_clientes'),
                        label: 'Límite de clientes:',
                        onChangeFunc: this.inputHandler.bind(null, 'limite_clientes'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'tag',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un tag para la empresa" />
                        },
                        placeholder: 'Ingrese el tag de la empresa',
                        value: enterpriseForm.get('tag'),
                        label: 'Tag:',
                        onChangeFunc: this.inputHandler.bind(null, 'tag'),
                        validators: ['required']
                    }
                ]
            },
            {
                title: 'Correo empresa',
                fieldsetClass: 'grid-4',
                data: [
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'nombre_email',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un nombre de correo" />
                        },
                        placeholder: 'Ingrese el nombre para el correo',
                        value: enterpriseForm.get('nombre_email'),
                        label: 'Nombre correo:',
                        onChangeFunc: this.inputHandler.bind(null, 'nombre_email'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'subject',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un asunto para el correo" />
                        },
                        placeholder: 'Ingrese el asunto para el correo',
                        value: enterpriseForm.get('subject'),
                        label: 'Asunto correo:',
                        onChangeFunc: this.inputHandler.bind(null, 'subject'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'emisor',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un emisor para el correo" />
                        },
                        placeholder: 'Ingrese el emisor para el correo',
                        value: enterpriseForm.get('emisor'),
                        label: 'Emisor:',
                        onChangeFunc: this.inputHandler.bind(null, 'emisor'),
                        validators: ['required']
                    },
                ]
            },
            {
                title: 'FTP empresa',
                fieldsetClass: 'grid-4',
                data: [
                    {
                        tag: 'input',
                        type: 'number',
                        name: 'ftp_ip',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar una IP FTP para la empresa" />
                        },
                        placeholder: 'Ingrese la IP FTP de la empresa',
                        value: enterpriseForm.get('ftp_ip'),
                        label: 'IP FTP:',
                        onChangeFunc: this.inputHandler.bind(null, 'ftp_ip'),
                        validators: []
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'ftp_user',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un usuario FTP para la empresa" />
                        },
                        placeholder: 'Ingrese el usuario FTP de la empresa',
                        value: enterpriseForm.get('ftp_user'),
                        label: 'Usuario FTP:',
                        onChangeFunc: this.inputHandler.bind(null, 'ftp_user'),
                        validators: []
                    },
                    {
                        tag: 'input',
                        type: 'number',
                        name: 'ftp_port',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un puerto FTP para la empresa" />
                        },
                        placeholder: 'Ingrese el puerto FTP de la empresa',
                        value: enterpriseForm.get('ftp_port'),
                        label: 'Puerto FTP:',
                        onChangeFunc: this.inputHandler.bind(null, 'ftp_port'),
                        validators: []
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'ftp_pass',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un password FTP para la empresa" />
                        },
                        placeholder: 'Ingrese el password FTP de la empresa',
                        value: enterpriseForm.get('ftp_pass'),
                        label: 'Password FTP:',
                        onChangeFunc: this.inputHandler.bind(null, 'ftp_pass'),
                        validators: []
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'nombre_archivo_ftp',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un nombre de archivo FTP para la empresa" />
                        },
                        placeholder: 'Ingrese el nombre de archivo FTP de la empresa',
                        value: enterpriseForm.get('nombre_archivo_ftp'),
                        label: 'Nombre archivo FTP:',
                        onChangeFunc: this.inputHandler.bind(null, 'nombre_archivo_ftp'),
                        validators: ['required']
                    },
                    {
                        tag: 'input',
                        type: 'text',
                        name: 'asunto_error_ftp',
                        labelClass: 'grid-12',
                        invalidSetup: {
                            icons: {
                                valid: 'icon-element check field-icon',
                                invalid: 'icon-element rounded info red field-icon',
                            },
                            tooltip: <Tooltip type="red" additionalClass="error in-field" content="Debes ingresar un asunto error FTP para la empresa" />
                        },
                        placeholder: 'Ingrese el asunto error FTP de la empresa',
                        value: enterpriseForm.get('asunto_error_ftp'),
                        label: 'Asunto error FTP:',
                        onChangeFunc: this.inputHandler.bind(null, 'asunto_error_ftp'),
                        validators: ['required']
                    }
                ]
            }

        ];

        return formFields;
    }
    getSuccessMessage() {
        const { adminEnterprisesStates } = this.props,
            message = adminEnterprisesStates.get('messageVariableForm');
        if (message.length > 0)
            return <Disclaimer type={'success'} content={message} />
    }
    getWarningMessage() {
        const { adminEnterprisesStates } = this.props,
            message = adminEnterprisesStates.get('enterpriseVariablesError');
        if (message.length > 0)
            return <Disclaimer type={'error'} content={message} />
    }

    getContent = () => {
        const { adminEnterprisesStates } = this.props,
            error = adminEnterprisesStates.get('error'),
            formFieldsets = this.getFormFieldsets();
        let content = null;

        if (error) {
            content = (
                <NotFoundData
                    text={error}
                    buttonText="Intentar nuevamente"
                    buttonFunc={this.fetchData}
                />
            );
        } else {
            content = (
                <FormValidizr
                    formSubmitHandler={this.handleSubmit}
                    formButton={{
                        buttonClass: 'btn',
                        text: "Editar variables empresa",
                        holder: 'align-center'
                    }}
                    formFields={formFieldsets}
                    formButtonHolder='align-center m-t-20'
                    formHolderClass="inner-header-content-holder row"
                />
            )
        }
        return content;
    }
    render() {
        const content = this.getContent(),
            successMessage = this.getSuccessMessage(),
            warningMessage = this.getWarningMessage();
        return (
            <div className="inner-content">
                {successMessage}
                {warningMessage}
                <div className="box">
                    {content}
                </div>
            </div>
        )
    }
}
