import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getTemplateElementById } from '../../utilities/utilities';

export default class CampaignBoxHeader extends Component{
  getTemplateName(){
    const { entities, campaign } = this.props,
          template = getTemplateElementById(campaign.get('id_plantilla'), entities.get('templates'));
    
    if(template.get(0))
      return template.get(0).get('nombre_plantilla');

    return false;
  }
  render(){
    const { campaign } = this.props,
           templateName = this.getTemplateName();

    let template = null;
    if(templateName){
      template = (
        <span className="campaign-box-header-indicator active">
         <i className="icon-element-1 inline-left templates-2"></i>
          { templateName }
        </span>
      );
    }

    return(
      <header className="campaign-box-wrapper campaign-box-header">
        <h2>{ campaign.get('numero_campana') }</h2>
        { template }
      </header>
    );
  }
}

CampaignBoxHeader.propTypes = {
  campaign: PropTypes.object
}
