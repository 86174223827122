import React, { Component } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import CampaignDays from '../modules/campaign-days';

export default class CampaignBoxDateResume extends Component{
  getDateDuration(){
    const { campaign } = this.props,
          today = moment().startOf('day'),
          startDate = moment(campaign.get('fecha_inicio')).startOf('day'),
          endDate = moment(campaign.get('fecha_termino')).startOf('day');

    return {
      duration: endDate.diff(startDate, 'days'),
      endIn: endDate.diff(today, 'days'),
      startIn: startDate.diff(today, 'days')
    }
    
  }
  getDateDisclaimer({ endIn, startIn }){
    const { campaign } = this.props;

    let dayString = 'días',
        textSuccess = 'en '+ endIn +' '+dayString,
        textInactive = 'en ' + startIn + ' '+dayString;

    if(campaign.get('estado_campana') == 'en curso'){
      if(endIn < 1)
        textSuccess = 'hoy';
      return <span className="small-text-disclaimer warning m-l-10">Termina { textSuccess }</span>;
    }else if(campaign.get('estado_campana') == 'no iniciada'){
      if(startIn < 1)
        textInactive = 'hoy';
      return <span className="small-text-disclaimer warning m-l-10">Comienza { textInactive }</span>;
    }else if(campaign.get('estado_campana') == 'cancelada'){
      return <span className="small-text-disclaimer error m-l-10">Cancelada</span>;
    }else{
      return <span className="small-text-disclaimer warning m-l-10">Finalizada</span>;
    }

    return null;
  }
  getTitle(){
    const { showTitle } = this.props;

    if(!showTitle){ return null }

    return <h4 className="m-b-10">Disponibilidad</h4>
  }
  getDates(){
    const { showDates, campaign } = this.props;
      let campaignStatus = '';

      if (campaign.get('estado_campana') === 'en curso') {
        campaignStatus = ' En progreso';
      } else if (campaign.get('estado_campana') === 'no iniciada') {
        campaignStatus = ' No ha iniciado';
      } else if (campaign.get('estado_campana') === 'cancelada') {
        campaignStatus = ' Cancelada';
      } else {
        campaignStatus = ' Terminada';
      }
  let fecha = campaign.get('fecha_hora_creacion');
  let horaCreacion = moment(fecha).format('HH:mm');

    if(!showDates){ return null; }
    
    return (
      <span className="secondary-data">
        { campaign.get('fecha_inicio') +' - '+ campaign.get('fecha_termino')} 
        <br />
        { "Hora Carga: " + horaCreacion + " horas" }
        <br />
        { "Hora Envío: " + campaign.get('hr_envio')+":00 horas"}
      </span>
    );
  }
  render(){
    const { campaign } = this.props,
          dateDurations = this.getDateDuration(),
          dateDisclaimer = this.getDateDisclaimer(dateDurations),
          title = this.getTitle(),
          dates = this.getDates()
          // timeSend = campaign.get('hr_envio')+":00";
    
    return (
      <div className="campaign-box-wrapper">
        { title }
        <span className="campaign-client-visualization-header">
          <div className="block-element">
            <i className="icon-element calendar inline-left"></i>
            { dateDurations.duration } Días
            { dateDisclaimer } 
          </div>
          { dates } 
          {/* { dates } Hora de envío { timeSend } */}
        </span>
      </div>
    );
  }
}

CampaignBoxDateResume.propTypes = {
  campaign: PropTypes.object,
  showTitle: PropTypes.bool,
  showDates: PropTypes.bool
}

CampaignBoxDateResume.defaultProps = {
  showTitle: true,
  showDates: true
}
