import React, { Component } from 'react';

import { getCampaignStatus } from '../../utilities/utilities';

import CampaignBoxHeader from './campaign-box-header';
import CampaignBoxUserResume from './campaign-box-user-resume';
import CampaignBoxDateResume from './campaign-box-date-resume';
import CampaignDays from '../modules/campaign-days';
import CampaignDaySunday from '../modules/campaign-day-sunday';
export default class CampaignBoxes extends Component {
  constructor(props) {
    super(props);

    this.openCampaign = this.openCampaign.bind(this);
  }
  openCampaign(id, event) {
    this.props.history.push('/campanas/' + id);
  }
  getItems() {
    const { campaignStates, campaigns, entities } = this.props;

    //Extrae booleano para evaluar si la empresa puede realizar campañas los dias domingos
    const holidaysSunday = entities.get('enterprises').toJS()
    const idEnterprise = sessionStorage.getItem("enterprise")
    const holidaysSundayMap = holidaysSunday.find(x => x.id == idEnterprise)
    const evaluationSunday = holidaysSundayMap.active_sunday_holidays

    return campaigns.map((campaign, index) => {
      const campaignStatus = getCampaignStatus(campaign.get('estado_campana')),
        displayGridClass = campaignStates.get('viewSwitch') == 'table' ? 'grid-12' : 'grid-4 xlarge-3',
        displayClass = campaignStates.get('viewSwitch') == 'table' ? 'table-view' : '';

      return (
        <div key={'campaign-' + campaign.get('id_campana')} className={`${displayGridClass}`}>
          <article className={`box campaign-box ${campaignStatus} ${displayClass}`} onClick={this.openCampaign.bind(null, campaign.get('id_campana'))}>
            <CampaignBoxHeader campaign={campaign} {...this.props} />
            <CampaignBoxUserResume campaign={campaign} {...this.props} showTitle={campaignStates.get('viewSwitch') != 'table'} />
            <CampaignBoxDateResume campaign={campaign} showTitle={false} showDates={campaignStates.get('viewSwitch') != 'table'} {...this.props} />

            {evaluationSunday ? (
              <div className="campaign-box-wrapper">
                <CampaignDaySunday
                  days={campaign.get('dias_envio')}
                  additionalHolderClass={'align-center'}
                />
              </div>
            ) : <div className="campaign-box-wrapper">
              <CampaignDays
                days={campaign.get('dias_envio')}
                additionalHolderClass={'align-center'}
              />
            </div>
            }

          </article>
        </div>
      );
    });
  }
  render() {
    const items = this.getItems();

    return (
      <div className="animated fadeIn">
        {items}
      </div>
    );
  }
}