import React, { Component } from 'react';
import { List } from 'immutable';

import CampaignsBoxesWrapper from './campaign-boxes-wrapper';

import InnerHeader from '../modules/inner-header';
import IsFetching from '../modules/is-fetching';
import NotFoundData from '../modules/not-found-data';
import FilterField from '../modules/filter-field';
import FilterSelector from '../modules/filter-selector';
import CampaignLoadMoreButton from './campaign-load-more-button';
import { getIdEnterprise } from '../../utilities/utilities';

export default class Campaigns extends Component{
  constructor(props){
    super(props);

    this.handleNewCampaignButton = this.handleNewCampaignButton.bind(this);
    this.filterCampaign = this.filterCampaign.bind(this);
    this.filterStatusCampaign = this.filterStatusCampaign.bind(this);
    this.filterTemplateCampaign = this.filterTemplateCampaign.bind(this);
    this.callAsyncData = this.callAsyncData.bind(this);
    this.filterAll = this.filterAll.bind(this);
  }

  handleNewCampaignButton(){
    this.props.history.push('/nueva-campana')
  }

  componentWillMount(){
    this.callAsyncData();
  }

  callAsyncData(){
    const  { currentUser,campaignStates } = this.props,
           userData = currentUser.get('userData'),
           empresa = getIdEnterprise(this.props),
           campaignTemplateFilter = parseInt(campaignStates.get('campaignTemplateFilter'), 10) || '',
           campaignStatusFilter = campaignStates.get('campaignStatusFilter') || '',
           campaignFilter = campaignStates.get('campaignFilter') || '';

    this.props.getCampaigns(userData.get('username'),0,20,empresa,campaignTemplateFilter,campaignStatusFilter,campaignFilter);
    this.props.getTemplates(userData.get('username'),getIdEnterprise(this.props));
  }

  getCampaigns(){
    const { entities, campaignStates } = this.props,
          campaigns = entities.get('campaigns'),
          filterValue = campaignStates.get('campaignFilter'),
          filterStatus = campaignStates.get('campaignStatusFilter'),
          filterTemplates = campaignStates.get('campaignTemplateFilter');

    let list = [];

    campaigns.forEach((data) => {
      const campaignId = data.get('id_campana').toString(),
            templateId = data.get('id_plantilla').toString();
      
      if(filterValue && campaignId.search(filterValue))
        return;

      if(filterStatus && data.get('estado_campana') != filterStatus)
        return;

      if(filterTemplates && templateId != filterTemplates)
        return;

      list.push(data);
    });

    return List(list);
  }

  getContent(){
    const { entities, campaignStates } = this.props,
          campaigns = entities.get('campaigns'),
          error = campaignStates.get('error');

    if(error){
      return (
        <div className="box inner">
          <NotFoundData 
            text={ error } 
            buttonText="Intentar nuevamente"
            buttonFunc={ this.callAsyncData }
          />
        </div>
      )
    }else{
      if(campaigns.size){
        return <CampaignsBoxesWrapper campaigns={ campaigns } {...this.props} />;
      }else if(!campaigns.size && !campaignStates.get('campaignFilter') && !campaignStates.get('campaignStatusFilter') && !campaignStates.get('campaignTemplateFilter')){
        return (
          <div className="box inner">
            <NotFoundData 
              text="Todavía no se ha cargado ninguna campaña" 
              buttonText="Nueva campaña"
              buttonFunc={ this.handleNewCampaignButton }
            />
          </div>
        )
      }else{
        return (
          <NotFoundData 
            text="No se han encontrado campañas con el actual filtro"
          />
        )
      }
    }     
  }

  filterCampaign(event){
    this.props.filterCampaign(event.target.value);
  }

  filterStatusCampaign(event){
    this.props.filterStatusCampaign(event.target.value);
  }

  filterTemplateCampaign(event){
    this.props.filterTemplateCampaign(event.target.value);
  }

  getTemplateOptions(){
    const { entities } = this.props;
    let options = [];
    options.push({
      key: '',
      value: 'Todas las plantillas'
    })

    entities.get('templates').forEach((data) => {
      options.push({
        key: data.get('id_plantilla'),
        value: data.get('nombre_plantilla')
      });
    })

    return options;
  }

  filterAll(){
    const  { currentUser, campaignStates } = this.props,
           userData = currentUser.get('userData'),
           empresa = getIdEnterprise(this.props),
           campaignTemplateFilter = parseInt(campaignStates.get('campaignTemplateFilter'), 10) || '',
           campaignStatusFilter = campaignStates.get('campaignStatusFilter') || '',
           campaignFilter = campaignStates.get('campaignFilter') || '';

    this.props.setFilterCampaigns(campaignTemplateFilter, campaignStatusFilter, campaignFilter)
    this.props.getCampaigns(userData.get('username'),0,20,empresa, campaignTemplateFilter, campaignStatusFilter, campaignFilter);
  }

  getFilters(){
    const { entities, campaignStates } = this.props,
          campaigns = entities.get('campaigns'),
          templatesOptions = this.getTemplateOptions();

    return (
      <div>
        <FilterSelector 
          label="Plantillas:"
          labelClass="relative-position"
          name="campaign-states"
          value={ campaignStates.get('campaignTemplateFilter') }
          onChangeFunc={ this.filterTemplateCampaign }
          options={templatesOptions}
        />
        <FilterSelector 
          label="Estado:"
          labelClass="relative-position"
          name="campaign-states"
          value={ campaignStates.get('campaignStatusFilter') }
          onChangeFunc={ this.filterStatusCampaign }
          options={[
            {
              key: '',
              value: 'Todos los estados'
            },
            {
              key: 'no iniciada',
              value: 'No iniciadas'
            },
            {
              key: 'en curso',
              value: 'En curso'
            },
            {
              key: 'finalizada',
              value: 'Finalizadas'
            },
            {
              key: 'cancelada',
              value: 'Canceladas'
            }
          ]}
        />
          <FilterField 
            label="Buscar por id:"
            labelClass="relative-position"
            name="campaign-filter"
            value={ campaignStates.get('campaignFilter') }
            onChangeFunc={ this.filterCampaign }
            placeholder = "Buscar Campañas..."
          />
        <div className="align-center">
          <button className="btn btn-small btn-secondary" onClick={ this.filterAll }>Filtrar</button>
        </div>
      </div>
    )
  }
  render(){
    const { campaignStates, templatesStates, entities } = this.props,
          content = this.getContent(),
          filters = this.getFilters();

    return (
      <div className="relative-position">
          <InnerHeader title="Campañas" counter={ entities.get('campaigns').size } additionalClass={ 'no-margin' } >
            <div className="header-actions-holder">
              <button className="btn btn-small m-l-10" onClick={ this.handleNewCampaignButton }>Nueva Campaña</button>
            </div>
          </InnerHeader>
          <div className="row clearfix">
            <div className="clearfix">
              <div className="toolbar">
                <h3>Filtros:</h3>
                { filters }
              </div>
              <div className="content-with-toolbar">
                <div className="content-tool-bar">
                  <div className="align-right">
                    <button className={`btn rounded-btn m-r-10 ${ campaignStates.get('viewSwitch') == 'card' ? 'active' : '' }`} onClick={ this.props.switchCampaignView.bind(null, 'card') }><i className="icon-element card-grid"></i></button>
                    <button className={`btn rounded-btn ${ campaignStates.get('viewSwitch') == 'table' ? 'active' : '' }`} onClick={ this.props.switchCampaignView.bind(null, 'table') }><i className="icon-element table-grid"></i></button>
                  </div>
                </div>
                <div className="content-with-toolbar-scrolled">
                  <IsFetching isFetching={ campaignStates.get('isFetching') || templatesStates.get('isFetching')} showChildren={ campaignStates.get('isFetchingMoreCampaign') }>
                    <div className="wrapper clearfix">
                      { content }
                    </div>
                    <CampaignLoadMoreButton { ...this.props } />
                  </IsFetching>
                </div>
              </div>
            </div>
          </div>
      </div>
    )
  }
}