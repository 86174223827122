import React, { Component } from 'react';

import SingleCampaignPayWays from './single-campaign-payways';
import SingleCampaignChannels from './single-campaign-channels';

export default class CampaignBoxAdditionalInfo extends Component{
  render(){
    return(
      <div className="campaign-box-wrapper">
        <h4 className="m-b-10">Información adicional</h4>
        <ul className="line-list no-horizontal-padding">
          <SingleCampaignPayWays { ...this.props } />
          <SingleCampaignChannels {  ...this.props } />
        </ul>
      </div>
    );
  }
}