import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getBarChartSetup, getIdEnterprise } from '../../utilities/utilities';

import Tabs from '../modules/tabs';
import ChartHolder from '../charts/chart-holder';
import DashboardBarChart from '../charts/bar-chart';
import Tooltip from '../modules/tooltip';
import IsFetching from '../modules/is-fetching';

export default class SingleCampaignTabs extends Component{
  getTabs(){
    const { currentCampaign, campaignStates, entities, currentUser, featuresStates } = this.props,
          features = featuresStates.get('features').get('campaignsFeatures'),
          id = currentCampaign.get('id_campana'),
          startDate = currentCampaign.get('fecha_inicio'),
          endDate = currentCampaign.get('fecha_termino'),
          userData = currentUser.get('userData').get('username');
    let tabs = [{
      title: 'Histórico',
      counter: () => {
        const historicCounter = campaignStates.get('tabs').get('historicCounter');

        if(historicCounter)
          return <span className="badge small">{ historicCounter}</span>
        else
          return false;
      },
      content: () => {
        return (
          <ChartHolder
            title={ 'Porcentaje de apertura' }
            getData={ this.props.getDashboardData.bind(null, {
              slug: 'openingPercentage',
              endpoint: 'campanas/DASHBOARD_APERTURA',
              ord: 'ord',
              data: {
                usuario: currentUser.getIn(['userData','username']),
                id_campana: id,
                fecha_inicio: currentCampaign.get('fecha_inicio'),
                fecha_termino: currentCampaign.get('fecha_termino'),
                reagendamiento: false,
                empresa: getIdEnterprise(this.props)
              }
            }) }
            b64={ campaignStates.get('b64').get('openingPercentage') }
          >
            <IsFetching isFetching={ campaignStates.get('fetchingTabs').get('openingPercentage')} >
              <DashboardBarChart 
                data={ campaignStates.get('tabs').get('openingPercentage')} 
                tooltipFormatter = {  (data) => { return Math.round(data) + '%' } }
                formatYAxis={ (data) => { return data + '%' } }
                yAxisLabel={ 'Aperturas' }
                setup={ getBarChartSetup(features) }
              />
            </IsFetching>
          </ChartHolder>
        )
      }
    }];

    return tabs;

  }
  render(){
    const { campaignStates } = this.props,
          tabs = this.getTabs();

    return (
      <Tabs 
        activeTab={campaignStates.get('tabs').get('historyActivetab')} 
        changeDashboardTab={this.props.changeCampaignHistoryTab}
        buttonsHolderClass={ 'tabs-buttons-holder small inner' }
        tabsHolderClass={'tabs-panels-holder'}
        tabs = {tabs}
      />
    );
  }
}

SingleCampaignTabs.propTypes = {
  currentCampaign: PropTypes.object
}
