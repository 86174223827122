import React, { Component } from 'react';
import { fromJS } from 'immutable';
import { getIndexBy, getIdEnterprise } from '../../utilities/utilities';

import Table from '../modules/table.jsx';
import Label from '../modules/label.jsx';

export default class EmailTemplatesTable extends Component{
  constructor(props){
    super(props);

    this.goToCampaign = this.goToCampaign.bind(this);
    this.prevEmail = this.prevEmail.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }
  confirmDelete(data, event){
    const { entities, currentUser } = this.props, 
          text = '¿Estás seguro que quieres eliminar la plantilla '+ data.get('name') +'?',
          func = this.props.deleteEmailTemplate.bind(null, getIndexBy(data.get('id'), entities.get('emailTemplates')), data.get('id'), currentUser.get('userData').get('username'), getIdEnterprise(this.props));

    this.props.confirmData(text, func);
  }
  getData(){
    const { entities, channelsConfigStates } = this.props,
          templates = entities.get('emailTemplates');

    let list = [];

    templates.forEach((template) => {
      list.push(template);
    });

    return fromJS(list)
  }
  goToCampaign(data){
    this.props.history.push('/campanas/'+ data)
  }
  getActionButtons(data){
    return (
      <div className="table-action-buttons-holder">
        <button className="btn btn-table" onClick={ this.confirmDelete.bind(null, data) }><i className="icon-element close"></i></button>
      </div>
    )
  }
  prevEmail(data, event){
    event.preventDefault();
    this.props.editEmailTemplate(data);
  }
  render(){
    const { entities, channelsConfigStates } = this.props,
          data = entities.get('emailTemplates');

    return (
      <Table 
        data={ data }
        columns={[
          {
            title: 'Nombre',
            order: true,
            func: (data) => {
              return <strong>{ data.get('name') }</strong>
            }
          },
          {
            title: 'Campañas asociadas',
            order: true,
            func: (data) => {
              return data.get('campaigns').map((campaign) => {
                return (
                  <Label key={ 'label-'+ campaign } label={ campaign } onClickHander={ this.goToCampaign.bind(null, campaign) } /> 
                )
              })
            }
          },
          {
            title: '',
            order: false,
            func: (data) => {
              return <a href="#" title="Previsualizar email" onClick={ this.prevEmail.bind(null, data) }>Previsualizar</a>
            }
          },
          {
            title: '',
            order: false,
            func: (data) => {
              if(!data.get('campaigns').size)
                return this.getActionButtons(data);
            }
          }
        ]}
      />
    );
  }
}