import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../modules/dropdown';
import UserDropdownNavigation from './user-dropdown-navigation';
import EnterpriseDropdownNavigation from './enterprise-dropdown-navigation';

export default class SecondaryNavigation extends Component{
  constructor(props){
    super(props);
    this.logout = this.logout.bind(this);
  }
  logout(event){
    event.preventDefault();
    const userData = JSON.parse(sessionStorage.getItem('loggIn'))
    this.props.logoutSessionAuth(userData);
  }
  fillEnterpriseSelect = () =>{
    const  { featuresStates, } = this.props,
    features = featuresStates.get('features');
    if(!features.get('admin')) return null;

    return(
    //   <Dropdown
    //   handlerClass="btn btn-small btn-user-navigation"
    //   icon={ 'icon-element inline-left arrow-down' }
    //   text={"Empresas"}
    // >
      <EnterpriseDropdownNavigation {...this.props}/>
    // </Dropdown>
    )
  }

  render(){
    const { adminEnterprisesStates } = this.props;
    const enterpriseId = adminEnterprisesStates.getIn(["selectedEnterprise", "id"]);
    const showLogo = enterpriseId === 153;
  
    return (
      <div className="user-navigation">
        <span>{this.props.userName}</span>
        {this.fillEnterpriseSelect()}
        <Dropdown
          handlerClass="btn btn-small btn-user-navigation"
          icon={ 'icon-element inline-left settings' }
        >
          <UserDropdownNavigation { ...this.props }/>
        </Dropdown>
        {showLogo && <div className="logo-icon"></div>}
        <button className="btn btn-user-logout" onClick={ this.logout }></button>
      </div>
    );
  }
}

SecondaryNavigation.propTypes = {
  userName: PropTypes.string
}
