import React, { Component } from 'react';
import { getIdEnterprise } from '../../utilities/utilities';

export default class Form extends Component{
  constructor(props){
    super(props);

    this.submitHandler = this.submitHandler.bind(this);
    this.inputHandler = this.inputHandler.bind(this);
  }
  submitHandler(event){
    event.preventDefault();
    const { assistantStates, assistantSettingsStates, currentUser } = this.props;

    this.props.sendAssistantMessage({
      cid: assistantStates.get('cid'),
      input: assistantStates.get('message'),
      contextID: 'TESTS',
      tipo: assistantSettingsStates.get('filteredChannel'),
      empresa: getIdEnterprise(this.props)
    }, -1, -1);
  }
  inputHandler(target, event){
    this.props.changeAssistantState(target, event.target.value);
  }
  getLoader(){
    const { assistantStates, assistantSettingsStates } = this.props;

    if(assistantStates.get('isSendingMessage')){
      return (
        <div className="assistant-loader">
          <span className="dot" style={{ backgroundColor: assistantSettingsStates.get('buttonsColor') }}></span>
          <span className="dot" style={{ backgroundColor: assistantSettingsStates.get('buttonsColor') }}></span>
          <span className="dot" style={{ backgroundColor: assistantSettingsStates.get('buttonsColor') }}></span>
        </div>
      );
    }

    return null;
  }
  getContent(){
    const { assistantSettingsStates, assistantStates } = this.props;

    if(!assistantStates.get('isSendingMessage') && !assistantStates.get('endSession') ){
      return (
        <div>
          <div className="assistant-form-wrapp">
            <input className="assistant-input-user" value={ assistantStates.get('message') } onChange={ this.inputHandler.bind(null, 'message') } placeholder="Escribe aquí tu mensaje..." name="message" />
            <button className="assistant-btn assistant-btn-light active" style={ { backgroundColor: assistantSettingsStates.get('buttonsColor'), borderColor: assistantSettingsStates.get('buttonsColor') } }><i className="icon-element send"></i></button>
          </div>
        </div>
      )
    }else{
      return null;
    }
  }
  render(){
    const content = this.getContent(),
          loader = this.getLoader(),
          error = this.props.assistantStates.get('errorFetching');
    return (
      <form action="#" method="POST" className={`assistant-input-user-holder ${error===""?'':' disabled'}`} onSubmit={ this.submitHandler }>
        { content }
        { loader }
      </form>
    );
  }
}