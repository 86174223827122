import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Tooltip extends Component{
  render(){
    return(
      <span className={'tooltip '+ this.props.additionalClass}>
        <i className={'icon-element rounded info '+ this.props.type}></i>
        <span className="tooltip-content">
          { this.props.content }
        </span>
      </span>
    );
  }
}

Tooltip.propTypes = {
  type: PropTypes.string,
  additionalClass: PropTypes.string,
  content: PropTypes.string
}

Tooltip.defaultProps = {
  type: 'light-blue'
}