import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { Route } from "react-router";
import * as actions from './actions/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import "./assets/styles/main.scss";

import Login from "./components/login/login";
import Dashboard from "./components/dashboard/dashboard";
import Templates from "./components/templates/templates";
import Campaigns from "./components/campaigns/campaigns";
import CampaignSingle from './components/campaigns/campaign-single';
import NewCampaign from './components/campaigns/new-campaign/new-campaign';
import AssistantSettings from './components/assistant/settings';
import Users from "./components/users/users";
import ChannelConfig from './components/channel-config/channel-config';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import NotFound from './components/not-found/not-found';
import AdminEnterprise from "./components/admin-enterprise/admin-enterprise";
import IsFetching from "./components/modules/is-fetching";

export class Router extends Component {
  componentWillMount() {
    moment.updateLocale('en', {
      months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
      weekdays: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
      weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
      weekdaysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"]
    });
    if (this.props.currentUser.get('isLoggedIn') && this.props.entities.get('enterprises').size === 0) {
      this.props.getEnterprises();
    }
  }
  shouldComponentUpdate(nextProps) {
    //LOGICA PARA TOMAR EMPRESAS
    //SI ESTÁ LOGUEADO Y NO SE HAN CARGADO LAS EMPRESAS
    if (nextProps.currentUser.get('isLoggedIn') && nextProps.entities.get('enterprises').size === 0 && !nextProps.entities.get('enterprisesIsFetching')) {
      this.props.getEnterprises();
    }
    return true;
  }
  getContent() {
    const { currentUser, featuresStates } = this.props;

    if (!this.props.currentUser.get('isLoggedIn')) {
      return (
        <BrowserRouter basename="/">
          <Switch>
            <Route key="login" path='/' render={(props) => { return <Login {...this.props} {...props} /> }} />
            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      );
    } else {
      const permissions = currentUser.get('userData').get('viewsPermissions'),
        features = featuresStates.get('features');

      let dashboardRoute, templatesRoute, campaignRoutes, newCampaignRoute, campaignSingleRoute, usersRoute, assistantRoute, channelConfigRoute, adminEnterpriseRoute;

      if (permissions.includes('dashboard') && features.get('dashboard'))
        dashboardRoute = <Route key="dashboard" exact path='/' render={(props) => { return <Dashboard {...this.props} {...props} /> }} />

      if (permissions.includes('templates') && features.get('templates'))
        templatesRoute = <Route key="templates" exact path='/plantillas' render={(props) => { return <Templates {...this.props} {...props} /> }} />

      if (permissions.includes('campaigns') && features.get('campaigns'))
        campaignRoutes = <Route key="campaigns" exact path='/campanas' render={(props) => { return <Campaigns {...this.props} {...props} /> }} />

      if (permissions.includes('campaigns') && features.get('campaigns'))
        campaignSingleRoute = <Route key="campaigns-single" exact path='/campanas/:id' render={(props) => { return <CampaignSingle {...this.props} {...props} /> }} />

      if (features.get('users'))
        usersRoute = <Route key="users" exact path='/users' render={(props) => { return <Users {...this.props} {...props} /> }} />

      if (permissions.includes('campaigns') && features.get('campaigns'))
        newCampaignRoute = <Route key="new-campaign" exact path='/nueva-campana' render={(props) => { return <NewCampaign {...this.props} {...props} /> }} />

      if (features.get('assistant'))
        assistantRoute = <Route key="asistente" exact path='/asistente' render={(props) => { return <AssistantSettings {...this.props} {...props} /> }} />

      // if(features.get('channelConfig'))
      channelConfigRoute = <Route key="channel-config" exact path='/configuracion/email' render={(props) => { return <ChannelConfig {...this.props} {...props} /> }} />

      if (features.get('admin'))
        adminEnterpriseRoute = <Route key="admin-enterprise" exact path='/admin-enterprise/' render={(props) => { return <AdminEnterprise {...this.props} {...props} /> }} />

      return (
        <div className="main-content">
          <BrowserRouter basename="/">
            <div>
              <Route render={(props) => (<Header {...props} {...this.props} />)} />
              {/* <Header {...props} {...this.props} /> */}
              <Switch>
                {dashboardRoute}
                {templatesRoute}
                {campaignRoutes}
                {campaignSingleRoute}
                {newCampaignRoute}
                {usersRoute}
                {assistantRoute}
                {channelConfigRoute}
                {adminEnterpriseRoute}
                <Route component={NotFound} />
              </Switch>
            </div>
          </BrowserRouter>
        </div>
      )
    }
  }
  render() {
    let content = this.getContent();
    if (this.props.entities.get('enterprisesIsFetching') || (this.props.currentUser.get('isLoggedIn') && this.props.entities.get('enterprises').size === 0)) {
      return null;
    }

    return (
      <div>
        {content}
        <Footer {...this.props} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);
